import moment from 'moment';
import Top    from './TopPart';
import Bottom from './BottomPart';


moment.locale('de');


const baseURL = window.location.origin;

const emailParse = function (email) {
    if (email) {
        let htmlText = `<p>&nbsp;</p><p>&nbsp;</p><div style="border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0 0 0">`;
        htmlText    +=      `<p class=MsoNormal><span style="mso-fareast-font-family:Times New Roman;mso-fareast-language:ZH-CN">`
        htmlText    +=          '<b>Von:</b> ' + (email.fromName ? email.fromName : email.fromAddress) + ' &lt;' + email.fromAddress + '&gt;' + '<br>'
        htmlText    +=          '<b>Gesendet:</b> ' + moment(email.date, 'DD.MM.YYYY HH:mm:ss').format('LLLL') + ' <br>'
        htmlText    +=          '<b>An:</b> ' + email.toAddresses.map(contact =>
                (contact.name ? contact.name : contact.email) + ' &lt;' + contact.email + '&gt;').join(', ')
            + '<br>';
        htmlText    += '        <b>Betreff:</b> ' + email.subject;
        htmlText    +=      '</span></p>'
        htmlText    +=  '</div>'

        if (email.textHtml) {
            let content = new DOMParser().parseFromString(email.textHtml, 'text/html');
            //     Removing base because it interferes with our js code   <base href="some_url">
            // TODO - this will break the email links - find a better solution
            htmlText += '<div dir="ltr"><br></div>' + content.body.innerHTML;

            //clean up old wrong code
            /*htmlText = htmlText.replace(
                new RegExp('\.\./\.\./', 'g'),
                '/'
            );*/

            if(email.attachments) {
                for (const attachment of email.attachments) {
                    if ((attachment.disposition === 'inline' || !attachment.disposition) && attachment.type === 'image') {
                        htmlText = htmlText.replace(
                            new RegExp('cid:' + attachment.id, 'g'),
                            '/files/' + attachment.filePath
                        );
                    }
                }
            }



        } else {
            htmlText += '<div dir="ltr"><br></div>' + email.textPlain;
        }

        return htmlText;

    } else {
        return '';
    }
};


export default function (body = '', phone = '', email = null) {
    return Top + `
    <div class=WordSection1 style='font-size:10.0pt;font-family:"Arial",sans-serif;mso-fareast-language:EN-US'>
        ${body}
    </div>

   ` +

        emailParse(email) +

        Bottom;
}
