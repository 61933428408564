import moment   from 'moment';
import {currentUser, fullYear} from '@utilities/functions';


moment.locale('de', {
    months : 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
    weekdays : 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
    weekdaysShort : 'Son_Mon_Die_Mit_Don_Fre_San'.split('_'),
    weekdaysMin : 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
});

export const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

export const tinyMceOptions = {
    selector: '#tinyMCE',
    plugins:
        'advlist ' +
        'autolink ' +
        'autoresize ' +
        'code ' +
        'fullscreen ' +
        'help ' +
        'hr ' +
        //'image imagetools ' +
        'link ' +
        'lists ' +
        'noneditable ' +
        'paste ' +
        'preview ' +
        'print ' +
        'searchreplace ' +
        //'spellchecker ' +
        'table ' +
        'template ' +
        'textpattern ' +
        'wordcount',
    toolbar:
        'fullscreen | ' +
        'fontsizeselect | ' +
        'bold italic underline | ' +
        'forecolor backcolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'indent outdent | ' +
        'numlist bullist | ' +
        'table template | ' +
        'removeformat undo redo',
    font_formats:
        'Open Sans=Open Sans, Helvetica Neue, sans-serif;' +
        'Andale Mono=andale mono,times; ' +
        'Arial=arial,helvetica,sans-serif; ' +
        'Arial Black=arial black,avant garde; ' +
        'Book Antiqua=book antiqua,palatino; ' +
        'Comic Sans MS=comic sans ms,sans-serif; ' +
        'Courier New=courier new,courier; ' +
        'Georgia=georgia,palatino; ' +
        'Helvetica=helvetica; ' +
        'Impact=impact,chicago; ' +
        'Symbol=symbol; ' +
        'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
        'Terminal=terminal,monaco; ' +
        'Times New Roman=times new roman,times; ' +
        'Trebuchet MS=trebuchet ms,geneva; ' +
        'Verdana=verdana,geneva; ' +
        'Webdings=webdings; ' +
        'Wingdings=wingdings,zapf dingbats',
    fontsize_formats:
        '1pt 8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt ' +
        '22pt 24pt 26pt 28pt 30pt 34pt 38pt 42pt 48pt 54pt 60pt',
    color_map: [
        '6A6C6F', 'Gray',
        'FFFFFF', 'White',
        'E78E7C', 'Red',
        'D6B874', 'Yellow',
        '87A873', 'Green',
        '0066cc', 'Blue',
        '60A4AB', 'Turquoise',
        '9588AF', 'Lila',
    ],
    formats: {
        catalog_format: { inline: 'span', styles: { color: '#0066cc' } }
    },
    style_formats: [
        { title: 'Katalogtext', format: 'catalog_format' },
    ],
    templates: [
        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
    ],
    min_height: 750,
    forced_root_block : false,
    indent_use_margin: true,
    style_formats_merge: true,
    visual: false,
    object_resizing : 'img',
    skin_url: '/assets/tinyMce/skins/ui/travelize',
    skin: 'travelize',
    content_css: '/assets/tinyMce/skins/content/travelize/content.min.css',
    language: 'de',
    spellchecker_language: 'de,fr_FR,en',
    browser_spellcheck: true,
    contextmenu: false,
    valid_children : '+body[style]',
    //spellchecker_rpc_url: '/assets/tinyMce/plugins/spellchecker/spellchecker.php',
    table_toolbar: false,
    //images_upload_url: '/api/images/uploadtest?bearer=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1ODQ4MzIzMTYsImV4cCI6MTU4NTQzNzExNiwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6IkxhdXJlbnoifQ.e92sjqdAzu-_E0DFOLZs3CLh2ioYS2CdD3mWSVrltcomSfGoMkbPMN9Y_rf5igHXdD5dP2oOAcKdFHaqdaL0ZhrVASn2p6G44O7FYS7E43ZANk9Va2Ze8zq1vFsHUbSc5YbGsmljz5WLk1P9QV9mklANMSDKbNUekEjMJGcRLp5ponxr-Yx9UddzdIe3i2RmmOqKENqjNl8Hhwy8JtQGCsggqFQZK4-bLTW5H5vY-AQqDte_wkQtxAkhD7q5DSndGlA5m1fD0pK5Xzufi292Ne9EE6MS9zwqC0c9IVUlo42IsZILLvqX-dKJH-7BP7v_X2ir9G9b8XnUTzRjFbVdZPtAk109_Bjbok4djEaN1O-qZ_TAxh9aVbZyQ8JAICfMJcn1MLytNPmxZznVLPtX-z6BRK9nxct8wlm743c5ztD9LC3K74F7-GwSyUobdEQQVxKJDFiaa-vawivGc0QtnRZVbyBombi9NBO1R8VCbOs4wTCDHFLC3Qo1zndXQRcI-K8cTL8q0lkngMNQtAnrhPGu3Y91MWo5eT1XCkJYppu1i6Ij0r9pAUXbAgPpeCLeYsUU1kmWPottLLxTTJuOJ3bRnB848Y85iDJf7O2FpvfpCwUkdUvYWyxtSOZ4uffuI4P9kCikFbdUMkkUQnBz3KsgrWKShU1qaYKEqlkKlgw'
    // noteditable part needs to have class='mceNonEditable'
    //toolbar_groups -> to get more space on toolbar
    extended_valid_elements: 'span[style],#p[class:MsoNormal|style],#td[class:MsoNormal|nowrap|valign|width|style|height|align],#li[class:MsoNormal],#div[class:MsoNormal|style]',
};

export const defaultAddConditionText = function (state, condition) {
    return '';
};

export const defaultConditions = function (state) {
    const offerdate = new Date();
    offerdate.setDate(offerdate.getDate() + 21);

    const startAt = moment(state.order.startAt, 'DD.MM.YYYY');

    let conditions = {};
    conditions.de_DE = [
        {
            category: 'Angebotsoption',
            subcategory: 'zum Paketpreis:',
            days: null,
            conditionText: '',
            type: 'date',
            key: `${+new Date()}-offer-1`,
            deadlineDate: offerdate.toLocaleDateString('de-DE'),
            deadlineText: ''
        },
        {
            category: 'Zahlungen',
            subcategory: 'von Paketleistungen:',
            days: 30,
            conditionText: 'Zahlung des Gesamtpreises',
            type: 'days',
            key: `${+new Date()}-payment-1`,
            deadlineDate: startAt.clone().subtract(30, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 30,
            conditionText: 'Kostenlose Stornierung des Gesamtarrangements',
            type: 'days',
            key: `${+new Date()}-cancelation-1`,
            deadlineDate: startAt.clone().subtract(30, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Es wird kein Freiplatz gewährt.',
            isVisible: false,
            key: `${+new Date()}-condition-0`,
            deadlineDate: startAt.clone().format('DD.MM.YYYY'),
            deadlineText: '',
            position: 0,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Ein Freiplatz ab 20 zahlenden Teilnehmern.',
            isVisible: true,
            key: `${+new Date()}-condition-1`,
            deadlineDate: startAt.clone().add(1, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 1,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Zwei Freiplätze ab 40 zahlenden Teilnehmern.',
            isVisible: false,
            key: `${+new Date()}-condition-2`,
            deadlineDate: startAt.clone().add(2, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 2,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Alle Preise verstehen sich netto und pro Person, ausser wenn im Angebot anders erwähnt.',
            isVisible: true,
            key: `${+new Date()}-condition-3`,
            deadlineDate: startAt.clone().add(3, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Der Termin ist provisorisch, gilt aber als Kalkulationsgrundlage.',
            isVisible: false,
            key: `${+new Date()}-condition-4`,
            deadlineDate: startAt.clone().add(4, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Die Buchungssituation wurde überprüft.',
            isVisible: false,
            key: `${+new Date()}-condition-5`,
            deadlineDate: startAt.clone().add(5, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Die Buchungssituation wurde nicht überprüft.',
            isVisible: true,
            key: `${+new Date()}-condition-6`,
            deadlineDate: startAt.clone().add(6, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Die Buchungssituation wurde teilweise überprüft.',
            isVisible: false,
            key: `${+new Date()}-condition-7`,
            deadlineDate: startAt.clone().add(7, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Hotels wie im Reiseverlauf erwähnt oder gleichwertige.',
            isVisible: false,
            key: `${+new Date()}-condition-8`,
            deadlineDate: startAt.clone().add(8, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Eintrittsgebühren sind im Angebot nicht enthalten, ausser wenn am rechten Rand durch einen * gekennzeichnet.',
            isVisible: true,
            key: `${+new Date()}-condition-9`,
            deadlineDate: startAt.clone().add(9, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Getränke und durchgehende Reiseleitung sind im Angebot nicht enthalten.',
            isVisible: true,
            key: `${+new Date()}-condition-10`,
            deadlineDate: startAt.clone().add(10, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Die Preise gelten unter Vorbehalt von Veränderungen der Kurtaxen.',
            isVisible: true,
            key: `${+new Date()}-condition-11`,
            deadlineDate: startAt.clone().add(11, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
    ];
    conditions.fr = [
        {
            category: 'Angebotsoption',
            subcategory: 'zum Paketpreis:',
            days: null,
            conditionText: '',
            type: 'date',
            key: `${+new Date()}-offer-1`,
            deadlineDate: offerdate.toLocaleDateString('de-DE'),
            deadlineText: ''
        },
        {
            category: 'Zahlungen',
            subcategory: 'von Paketleistungen:',
            days: 30,
            conditionText: 'Paiement du solde',
            type: 'days',
            key: `${+new Date()}-payment-1`,
            deadlineDate: startAt.clone().subtract(30, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 30,
            conditionText: 'Annulation gratuite de tout l\'arrangement',
            type: 'days',
            key: `${+new Date()}-cancelation-1`,
            deadlineDate: startAt.clone().subtract(30, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Aucune gratuité n’est accordée.',
            isVisible: false,
            key: `${+new Date()}-condition-0`,
            deadlineDate: startAt.clone().format('DD.MM.YYYY'),
            deadlineText: '',
            position: 0,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Une personne gratuite par 20 payantes.',
            isVisible: true,
            key: `${+new Date()}-condition-1`,
            deadlineDate: startAt.clone().add(1, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 1,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Deux personne gratuite par 40 payantes.',
            isVisible: false,
            key: `${+new Date()}-condition-2`,
            deadlineDate: startAt.clone().add(2, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 2,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Tous les prix sont nets et par personne, sauf indication contraire.',
            isVisible: true,
            key: `${+new Date()}-condition-3`,
            deadlineDate: startAt.clone().add(3, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 3,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'La date est provisoire, mais valable pour le calcul de l’offre.',
            isVisible: false,
            key: `${+new Date()}-condition-4`,
            deadlineDate: startAt.clone().add(4, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 4,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'La situation de réservation a été vérifiée.',
            isVisible: false,
            key: `${+new Date()}-condition-5`,
            deadlineDate: startAt.clone().add(5, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'La situation de réservation n’a pas été vérifiée.',
            isVisible: false,
            key: `${+new Date()}-condition-6`,
            deadlineDate: startAt.clone().add(6, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'La situation de réservation a été partiellement vérifiée.',
            isVisible: true,
            key: `${+new Date()}-condition-7`,
            deadlineDate: startAt.clone().add(7, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Hôtels comme indiqués dans l’offre ou équivalents.',
            isVisible: true,
            key: `${+new Date()}-condition-8`,
            deadlineDate: startAt.clone().add(8, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 8,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Frais d’entrée non compris sauf si mentionnés dans l’itinéraire par un astérisque *',
            isVisible: true,
            key: `${+new Date()}-condition-9`,
            deadlineDate: startAt.clone().add(9, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 9,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Boissons et guide accompagnateur permanent non-compris dans le forfait.',
            isVisible: true,
            key: `${+new Date()}-condition-10`,
            deadlineDate: startAt.clone().add(10, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 10
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Les prix s’entendent sous réserve de modification de la taxe de séjour.',
            isVisible: true,
            key: `${+new Date()}-condition-11`,
            deadlineDate: startAt.clone().add(11, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
        },
    ];
    conditions.en = [
        {
            category: 'Angebotsoption',
            subcategory: 'zum Paketpreis:',
            days: null,
            conditionText: '',
            type: 'date',
            key: `${+new Date()}-offer-1`,
            deadlineDate: offerdate.toLocaleDateString('de-DE'),
            deadlineText: ''
        },
        {
            category: 'Zahlungen',
            subcategory: 'von Paketleistungen:',
            days: 30,
            conditionText: 'Payment of the total price',
            type: 'days',
            key: `${+new Date()}-payment-1`,
            deadlineDate: startAt.clone().subtract(30, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 30,
            conditionText: 'Cancellation of the entire arrangement free of charge',
            type: 'days',
            key: `${+new Date()}-cancelation-1`,
            deadlineDate: startAt.clone().subtract(30, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'No free place will be granted.',
            isVisible: false,
            key: `${+new Date()}-condition-0`,
            deadlineDate: startAt.clone().format('DD.MM.YYYY'),
            deadlineText: '',
            position: 0,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'One free place from 20 paying participants.',
            isVisible: true,
            key: `${+new Date()}-condition-1`,
            deadlineDate: startAt.clone().add(1, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 1,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Two free places from 40 paying participants.',
            isVisible: false,
            key: `${+new Date()}-condition-2`,
            deadlineDate: startAt.clone().add(2, 'day').format('DD.MM.YYYY'),
            deadlineText: '',
            position: 2,
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'All prices are net and per person, unless otherwise stated in the offer.',
            isVisible: true,
            key: `${+new Date()}-condition-3`,
            deadlineDate: startAt.clone().add(3, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'The date is provisional, but serves as a basis for calculation.',
            isVisible: false,
            key: `${+new Date()}-condition-4`,
            deadlineDate: startAt.clone().add(4, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'The booking situation has been checked.',
            isVisible: false,
            key: `${+new Date()}-condition-5`,
            deadlineDate: startAt.clone().add(5, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'The booking situation has not been checked.',
            isVisible: true,
            key: `${+new Date()}-condition-6`,
            deadlineDate: startAt.clone().add(6, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'The booking situation has been partially checked.',
            isVisible: false,
            key: `${+new Date()}-condition-7`,
            deadlineDate: startAt.clone().add(7, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Hotels as mentioned in the itinerary or equivalent.',
            isVisible: false,
            key: `${+new Date()}-condition-8`,
            deadlineDate: startAt.clone().add(8, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Entrance fees are not included in the offer, unless marked with an * in the right margin.',
            isVisible: true,
            key: `${+new Date()}-condition-9`,
            deadlineDate: startAt.clone().add(9, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'Drinks and a tour guide are not included in the offer.',
            isVisible: true,
            key: `${+new Date()}-condition-10`,
            deadlineDate: startAt.clone().add(10, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Konditionen',
            subcategory: '',
            days: null,
            conditionText: 'The prices are subject to changes in visitor\'s tax.',
            isVisible: true,
            key: `${+new Date()}-condition-11`,
            deadlineDate: startAt.clone().add(11, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
    ];

    if (!state.order.orderLocale || !conditions[state.order.orderLocale]) {
        state.order.orderLocale = 'de_DE';
    }

    return conditions[state.order.orderLocale];
};

export const defaultFreeplaces = function () {
    return [
        {
            freePlaces: 1,
            numberOfPersons: 21,
            type: 'single',
            key: 1
        },
        {
            freePlaces: 1,
            numberOfPersons: 42,
            type: 'single',
            key: 2
        },
        {
            freePlaces: 1,
            numberOfPersons: 63,
            type: 'single',
            key: 3
        },
        {
            freePlaces: 1,
            numberOfPersons: 84,
            type: 'single',
            key: 4
        },
        {
            freePlaces: 1,
            numberOfPersons: 105,
            type: 'single',
            key: 5
        },
        {
            freePlaces: 1,
            numberOfPersons: 126,
            type: 'single',
            key: 6
        },
    ];
};

export const defaultOrderContingent = function () {
    return {
        hotelContingent: {
            single: '0',
            double: '0'
        },
        ferryContingent: {
            singleCabins: '0',
            doubleCabins: '0'
        },
    };
};

export const defaultHotelPlaceholderValues = {
    hotelType: { category: '', stars: '3' },
    board:     'half',
    defaultHotelTimes: [
        { type: 'dinner', time: '20:00:00', firstNightOnly: false},
        { type: 'checkIn', time: '18:00:00', firstNightOnly: true },
        { type: 'checkIn', time: '00:00:00' , firstNightOnly: false },
        { type: 'breakfast', time: '07:30:00', firstNightOnly: false}
    ],
};

export const defaultDayValues = {
    isAutomaticName:     true,
    //addDayWithoutForm: true,
};

export const defaultEmailTags = [
    { id: 1, name: 'Kunde Anfrage', class: '', icon: 'person_outline' },
    { id: 2, name: 'Kunde Angebot', class: '', icon: 'person_outline' },
    { id: 3, name: 'Kunde Absage', class: '', icon: 'person_outline' },
    { id: 4, name: 'Kunde Reservierung', class: '', icon: 'person_outline' },
    { id: 5, name: 'Kunde Auftragsbestätigung', class: '', icon: 'person_outline' },
    { id: 6, name: 'Kunde Buchungsstand', class: '', icon: 'person_outline' },
    { id: 7, name: 'Kunde Stornierung', class: '', icon: 'person_outline' },
    { id: 8, name: 'Kunde Allgemein', class: '', icon: 'person_outline' },
    { id: 9, name: 'Hotel Anfrage', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 10, name: 'Hotel Angebot', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 11, name: 'Hotel refus', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 12, name: 'Hotel Option', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 13, name: 'Hotel Absage', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 14, name: 'Hotel Reservierung', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 15, name: 'Hotel Rückbestätigung', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 16, name: 'Hotel Buchungsstand', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 17, name: 'Hotel Stornierung', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 18, name: 'Hotel Allgemein', class: 'is-blue-dark', icon: 'local_hotel' },
    { id: 19, name: 'Zusatzleistung Anfrage', class: '', icon: 'more_horiz' },
    { id: 20, name: 'Zusatzleistung Angebot', class: '', icon: 'more_horiz' },
    { id: 21, name: 'Zusatzleistung refus', class: '', icon: 'more_horiz' },
    { id: 22, name: 'Zusatzleistung Option', class: '', icon: 'more_horiz' },
    { id: 23, name: 'Zusatzleistung Absage', class: '', icon: 'more_horiz' },
    { id: 24, name: 'Zusatzleistung Reservierung', class: '', icon: 'more_horiz' },
    { id: 25, name: 'Zusatzleistung Rückbestätigung', class: '', icon: 'more_horiz' },
    { id: 26, name: 'Zusatzleistung Buchungsstand', class: '', icon: 'more_horiz' },
    { id: 27, name: 'Zusatzleistung Stornierung', class: '', icon: 'more_horiz' },
    { id: 28, name: 'Zusatzleistung Allgemein', class: '', icon: 'more_horiz' },
];

export const defaultRoomListTypes = [
    {
        value: 'hotel_pdf',
        name: 'Zimmerliste als PDF'
    },
    {
        value: 'hotel_short',
        name: 'Zimmerliste als Word'
    },
    {
        value: 'hotel_long_word',
        name: 'Passagierliste als Word'
    },
    {
        value: 'hotel_long_pdf',
        name: 'Passagierliste als PDF'
    },
    {
        value: 'hotel_excel',
        name: 'Passagierliste als Excel'
    },
];

export const defaultRoomListPage = {
    type: 'hotel_pdf'
};

export const clientInvoiceIntroEndText = function (form, oldInvoiceId, currency) {
    const { amount, type } = form;
    const dueDate = fullYear(form.dueDate);


    return ``;
};

export const clientInvoiceIntroText = function (form, oldInvoiceId, currency) {
    return '';
};

export const defaultTripDetailGenerateSettings = {
    _route: null,
    _generalInfos: null,
    _questionaire: null,
    print: null,
    coverLetter: false,
};

export const defaultItemPrice = {
    name: 'Zuschlag',
    quantityType: 'per_person',
    limitType: 'validIfLimit',
    quantity: null,
    billingType: 'additional',
    inCalculation: true,
};

export const defaultConceptClientId = 73;

export const otherTypesExcluded = [
    'financial',
];
export const defaultTags = {
    hotels: [
        'Hotel im Stadtzentrum',
        'Hotel am Stadtrand',
        'Hotel außerhalb',
        'Hotel im Gewerbegebiet',
        'Strandhotel',
        'Strandnähe',
        'Klimaanlage',
        'Minibar',
        'Safe',
        'Deutsches TV',
        'Schwimmbad (innen)',
        'Schwimmbad (außen)',
        'Spa',
        'Fahrstuhl',
        'Restaurant',
        'Bar'
    ]
};

export const additionalProviderProperties = [
];

export const driverRoomType = {
    type: 'standard',
    persons: 1,
};
export const driverCabinType = null;

export const defaultUserDashboardFilters = function () {
    return {
        isRequests: true,
        type: 'orders',

        // Filters
        _search: '',
        tripName: '',

        hotel: {
            destination: null
        },
        ferry: {
            startPort: null,
            endPort: null,
        },
        train: {
            startTrainStation: null,
            endTrainStation: null,
        },
        airline: {
            startAirport: null,
            endAirport: null
        },
        other: {
            otherServiceTypeCategory: null,
        },

        // Orders
        destinations: [],
        country: null,
        organisation: null,
        durationDays: null,

        // Request
        agency: null,
        orderStatus: [
            'new',
            'offer_sent',
            'provisional_offer_sent',
            'offer_accepted',
            'booking_accepted',

        ],
        startAt: null,
        endAt: null,
        requestStatus: [],
        users: [currentUser()],
        traitTags: [],

        // Placeholder
        notRequestStatus: null,
        clientOfferStatus: null,
        location: {
            area: null,
            destination: null
        },
    };
};
