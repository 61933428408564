export const emptyAddress = {
    usage:          '',
    type:           undefined,
    country:        undefined,
    city:           '',
    streetAddress:  '',
    zip:            ''
};


export const emptyContact = {
    role:      '',
    type:      'primary',
    firstName: '',
    lastName:  '',
    email:     '',
    language:  ''
};


export const googleMapForm = {
    longitude: null,
    latitude: null,
    longitude2: null,
    latitude2: null,
    centerLongitude: null,
    centerLatitude: null,
    zoom: 15,

    location: '',
    location2: '',

    mode: '',

    isRouteMap: false
};


export const bulletPointSections = [
    {
        key: 'hotel',
        name: 'Hotels',
        content: '<ul><li>alle Hotelübernachtungen mit verstärktem Frühstück oder Frühstücksbuffet</li><li>bei Beispielhotels erfolgt die endgültige Festlegung spätestens 30 Tage vor Abreise</li><li>eventuelle Kontingentanpassung bleiben unter Berücksichtigung der Entwicklung des Buchungsverlaufs vorbehalten</li><li>Kurtaxen, sofern nicht angegeben, sind nicht im Preis inbegriffen</li></ul>',
    },
    {
        key: 'ferry',
        name: 'Fähren',
        content: '<ul><li>Angebot ist unverbindlich bis zur Rückbestätigung der Fährüberfahrten durch die Reedereien</li><li>vorgesehene Fährüberfahrten basieren auf Erfahrungswerten der letzten Jahre; bei eventuellen Änderungen bieten wir Ihnen eine sinnvolle Alternativlösung an</li><li>eventuelle Fahrplanänderungen seitens der Reedereien bleiben vorbehalten</li></ul><ul><li>Sicherheitsgebühren, Hafensteuern und Treibstoffzuschläge sind im Reisepreis enthalten; bei eventuellen Erhöhungen wird der Reisepreis entsprechend angepasst</li></ul>'
    },
    {
        key: 'general',
        name: 'Allgemein',
        content: '<ul><li>alle Reiseleiterleistungen beinhalten mögliche Übernachtungskosten und Spesen</li><li>Eintrittsgelder, sofern in den Leistungen nicht angegeben, sind nicht im Preis inbegriffen</li><li>evtl. anfallende Park- und Einreisegebühren sind nicht berücksichtigt</li><li>bei Auftragsbestätigung erteilen Sie Ihre Einwilligung, dass wir Daten von Ihnen und Ihren Kunden zur Bearbeitung des Vorgangs erheben und verarbeiten dürfen. Diese Einwilligung können Sie jederzeit per Email widerrufen. Ein Vertrag zur Auftragsverarbeitung wird nach Einschätzung unseres Verbands nicht benötigt, kann aber auf Wunsch gerne mit Auftragsbestätigung abgeschlossen werden</li><li>es gelten unsere allgemeinen Geschäftsbedingungen für Wiederverkäufer; diese sowie detaillierte Informationen zum Datenschutz finden Sie unter www.buspaket.de</li></ul>',
    },
    {
        key: 'airlines',
        name: 'Airlines',
        content: 'Bitte aus Angebotsvorlagen kopieren'
    },
    {
        key: 'hiking',
        name: 'Wanderungen',
        content: '<ul><li>Bei Wanderreiseleitern handelt es sich um Reiseleiter, die teilweise nicht über die spezifischen Qualifikationen eines zertifizierten Reiseführers verfügen, sodass sie ggf. keine Erlaubnis haben bei bestimmten Sehenswürdigkeiten oder Städten eine Führung durchzuführen. Dafür kann bei Bedarf eine zusätzliche Reiseführung gebucht werden.</li><li>Sollte die endgültige Teilnehmerzahl das angegebene Verhältnis von Wanderreiseleiter zu Pax übersteigen, muss mit der Wanderreiseleitung geprüft werden, ob ein zweiter Wanderreiseleiter bzw. ein Reisebegleiter erforderlich ist. Dies muss zusätzlich kalkuliert werden.</li><li>Der Wanderreiseleiter behält sich vor, den Streckenverlauf der Wandertouren den Witterungsbedingungen bzw. der Leistungsstärke der Gruppe bzw. aus anderen Gründen individuell anzupassen.</li><li>Ein gewisses Maß an körperlicher Fitness sowie Trittsicherheit ist Voraussetzung. Bei den Teilnehmern dürfen keine körperlichen oder mentalen Einschränkungen vorliegen.</li><li>Die Angaben zu Streckenlänge, benötigter Zeit und Höhenmetern bei den Wanderungen werden nach bestem Wissen und Gewissen gemacht und sind größtenteils mit GPS-Geräten aufgezeichnet worden. Abweichungen können jedoch nie ausgeschlossen werden.</li><li>Buspaket Derenbach haftet nicht für Personen- und Sachschäden jedweder Art.</li></ul>'
    },
    {
        key: 'bike',
        name: 'Radtouren',
        content: '<ul><li>Bei Radreiseleitern handelt es sich um Reiseleiter mit besonderer Ausbildung für die Leitung von Radgruppen. Sie verfügen jedoch nicht über die spezifischen Qualifikationen eines zertifizierten Reiseführers, sodass sie ggf. keine Erlaubnis haben bei bestimmten Sehenswürdigkeiten oder Städten eine Führung durchzuführen. Dafür kann auf Wunsch eine zusätzliche Reiseführung gebucht werden.</li><li>Sollte die endgültige Teilnehmerzahl das angegebene Verhältnis von Radreiseleiter zu Pax übersteigen, muss mit der Radreiseleitung geprüft werden, ob ein zweiter Radreiseleiter bzw. ein Reisebegleiter erforderlich ist. Dies muss zusätzlich kalkuliert werden.</li><li>Der Radreiseleiter behält sich vor, den Streckenverlauf der Radtouren den Witterungsbedingungen bzw. der Leistungsstärke der Gruppe bzw. aus anderen Gründen individuell anzupassen.</li><li>Die Angaben zu Streckenlänge, Höhenmetern und Durchschnittssteigungen bei den Radtouren werden nach bestem Wissen und Gewissen gemacht und sind größtenteils mit GPS-Geräten aufgezeichnet worden. Abweichungen können jedoch nie ausgeschlossen werden.</li><li>Die Fahrräder bzw. E-Bikes aller Teilnehmer müssen sich in verkehrstauglichem Zustand befinden.</li><li>Während der Radtouren gilt grundsätzlich Helmpflicht für alle Teilnehmer.</li><li>Buspaket Derenbach haftet nicht für Personen- und Sachschäden jedweder Art.</li></ul>'
    },
    {
        key: 'route',
        name: 'Route',
        content: '<ul><li>Änderungen in der Routenplanung bleiben vorbehalten.</li><li>Das von uns konzipierte Reiseprogramm ist für einen bis zu 13 Meter langen und 4 Meter hohen Reisebus mit 3-Achsen vorgesehen. Sollten Sie für diese Reise einen größeren Bus einsetzen wollen, müssten wir die Routenplanung dementsprechend überprüfen und gegebenenfalls neu ausarbeiten.</li></ul>',
    }
];


export const introTitle = [
    {
        key: 'formal',
        label: 'Förmlich: Sie'
    },
    {
        key: 'informal',
        label: 'Informell: Du'
    }
];




export const tripDetailTypes = {
    provider_info: 'RU - Tabellarische Infos',
    box_map: 'RU - Hinweis',
    box: 'RU - Hinweisbox'
};


export const emptyOtherService = {
    provider:           undefined,
    otherServiceType:   undefined,
    place:              undefined,
    area:               undefined,
    routeDescription:   ''
};


export const languages = [
    { label: 'de', name: 'Deutsch' },
    { label: 'fr', name: 'Französisch' },
    { label: 'en', name: 'Englisch' },
    { label: 'it', name: 'Italienisch' },
    { label: 'nl', name: 'Niederländisch' },
    { label: 'be', name: 'Belgisch' },
    { label: 'es', name: 'Spanisch'}
];

export const exportLanguages = [
    { value: 'german', label: 'deutsch' },
    { value: 'french', label: 'französisch' },
];

export const weekDays = [
    {
        value: 'monday',
        label: 'Montag',
    },
    {
        value: 'tuesday',
        label: 'Dienstag',
    },
    {
        value: 'wednesday',
        label: 'Mittwoch',
    },
    {
        value: 'thursday',
        label: 'Donnerstag',
    },
    {
        value: 'friday',
        label: 'Freitag',
    },
    {
        value: 'saturday',
        label: 'Samstag',
    },
    {
        value: 'sunday',
        label: 'Sonntag',
    }
];

export const years = [
    '19',
    '20',
    '21',
    '22',
    '23',
    '24',
    '25'
];

export const tags = [
    { label: 'new',  name: 'Neu' },
];

export const tagIcons = [
    { label: 'star', name: 'Bestseller' },
    { label: 'newspaper', name: 'Newsletter' },
    { label: 'monetization_on',     name: 'Sonderangebot' },
    { label: 'directions_bus',      name: 'Busreise' },
    { label: 'airplanemode_active', name: 'Flugreise' },
    { label: 'directions_railway',  name: 'Zugreise' },
    { label: 'directions_walk',     name: 'Wanderreise' },
    { label: 'directions_bike',     name: 'Radreise' },
    { label: 'account_balance',     name: 'Studienreise' },
    { label: 'beach_access',        name: 'Badereise' },
    { label: 'ac_unit',             name: 'Winterreise' },
    { label: 'restaurant_menu',     name: 'Kulinarische Reise' },
    { label: 'flare',               name: 'Premiumreise' },
    { label: 'home',                name: 'Select Hotels' },


    // { label: 'directions_boat',     name: 'Boat' },
    // { label: 'directions_car',      name: 'Car' },
    // { label: 'directions_subway',   name: 'Subway' },
    //{ label: 'fitness_center',      name: 'Fitness' }
];

export const emailIcons = [
    'mail',
    'star',
    'list',
    'close',
    'check',
    'local_hotel',
    'more_horiz',
    'person_outline'
]

export const emailClasses = [
    {
        key: '',
        value: 'keine'
    },
    {
        key: 'is-blue-dark',
        value: 'blau'
    }
]




export const serviceIcons = {
    tour_guide:           'record_voice_over',
    boat_trip:            'rowing',
    restaurant:           'restaurant_menu',
    food_on_board:        'restaurant_menu',
    entrances_and_fees:   'monetization_on',
    conservation_program: 'album',
    train_ride:           'directions_railway',
    bus_presentation:     'directions_bus',
    tastings:             'local_bar',
    tourist_train:        'directions_transit',
    other:                'view_module'
};


export const contingentRoomTypes = {
    standard: 'Standard',
    deluxe:   'Deluxe',
    sea_view: 'Meerblick'
};


export const roomTypes = [
    {
        persons: 1,
        type: 'standard',
        label: 'Einzelzimmer'
    },
    {
        persons: 2,
        type: 'standard',
        label: 'Doppelzimmer'
    },
    {
        persons: 1,
        type: 'deluxe',
        label: 'Einzelzimmer Superior'
    },
    {
        persons: 2,
        type: 'deluxe',
        label: 'Doppelzimmer Superior'
    },
    {
        persons: 1,
        type: 'sea_view',
        label: 'Einzelzimmer Meerblick'
    },
    {
        persons: 2,
        type: 'sea_view',
        label: 'Doppelzimmer Meerblick'
    }
];

export const roomOtherTypes = [
    {
        persons: 3,
        label: 'Dreibettzimmer'
    },
    {
        persons: 4,
        label: 'Vierbettzimmer'
    },
    {
        persons: 5,
        label: 'Fünfbettzimmer '
    },
    {
        persons: 6,
        label: 'Sechsbettzimmer '
    },
];

export const cabinTypes = [
    {
        persons: 1,
        type: 'inside',
        label: 'EK Innen'
    },
    {
        persons: 2,
        type: 'inside',
        label: 'DK Innen'
    },
    {
        persons: 1,
        type: 'outside',
        label: 'EK Außen'
    },
    {
        persons: 2,
        type: 'outside',
        label: 'DK Außen'
    },
    {
        persons: 3,
        type: 'inside',
        label: 'Dreibettkabine Innen'
    },
    {
        persons: 4,
        type: 'inside',
        label: 'Vierbettkabine Innen'
    },
    {
        persons: 3,
        type: 'outside',
        label: 'Dreibettkabine Außen'
    },
    {
        persons: 4,
        type: 'outside',
        label: 'Vierbettkabine Außen'
    },
    {
        persons: 3,
        type: 'inside',
        label: 'Dreibettkabine Innen'
    },
    {
        persons: 4,
        type: 'inside',
        label: 'Vierbettkabine Innen'
    },
    {
        persons: 3,
        type: 'outside',
        label: 'Dreibettkabine Außen'
    },
    {
        persons: 4,
        type: 'outside',
        label: 'Vierbettkabine Außen'
    },
    {
        persons: 5,
        type: 'inside',
        label: 'Fünfbettkabine Innen'
    },
    {
        persons: 6,
        type: 'inside',
        label: 'Sechsbettkabine Innen'
    },
    {
        persons: 5,
        type: 'outside',
        label: 'Fünfbettkabine Außen'
    },
    {
        persons: 6,
        type: 'outside',
        label: 'Sechsbettkabine Außen'
    }
];

export const cabinOtherTypes = [
    {
        persons: 3,
        label: 'Dreibettkabine'
    },
    {
        persons: 4,
        label: 'Vierbettkabine'
    },
    {
        persons: 5,
        label: 'Fünfbettkabine '
    },
    {
        persons: 6,
        label: 'Sechsbettkabine '
    },
];


export const currencies = {
    EUR: '€',
    USD: '$',
    GBP: '£',
};

export const countries = [
    { label: 'DE', name: 'Deutschland' },
    { label: 'AT', name: 'Österreich' },
    { label: 'CH', name: 'Schweiz' },
    { label: 'LU', name: 'Luxemburg' },
    { label: 'AF', name: 'Afghanistan' },
    { label: 'EG', name: 'Ägypten' },
    { label: 'AX', name: 'Åland' },
    { label: 'AL', name: 'Albanien' },
    { label: 'DZ', name: 'Algerien' },
    { label: 'AS', name: 'Amerikanisch-Samoa' },
    { label: 'AD', name: 'Andorra' },
    { label: 'AO', name: 'Angola' },
    { label: 'AI', name: 'Anguilla' },
    { label: 'AQ', name: 'Antarktis' },
    { label: 'AG', name: 'Antigua/Barbuda' },
    { label: 'GQ', name: 'Äquatorialguinea' },
    { label: 'AR', name: 'Argentinien' },
    { label: 'AM', name: 'Armenien' },
    { label: 'AW', name: 'Aruba' },
    { label: 'AZ', name: 'Aserbaidschan' },
    { label: 'ET', name: 'Äthiopien' },
    { label: 'AU', name: 'Australien' },
    { label: 'BS', name: 'Bahamas' },
    { label: 'BH', name: 'Bahrain' },
    { label: 'BD', name: 'Bangladesh' },
    { label: 'BB', name: 'Barbados' },
    { label: 'BE', name: 'Belgien' },
    { label: 'BZ', name: 'Belize' },
    { label: 'BJ', name: 'Benin' },
    { label: 'BM', name: 'Bermuda' },
    { label: 'BT', name: 'Bhutan' },
    { label: 'BO', name: 'Bolivien' },
    { label: 'BA', name: 'Bosnien/Herzegowina' },
    { label: 'BW', name: 'Botsuana' },
    { label: 'BV', name: 'Bouvetinsel' },
    { label: 'BR', name: 'Brasilien' },
    { label: 'BN', name: 'Brunei Darussalam' },
    { label: 'BG', name: 'Bulgarien' },
    { label: 'BF', name: 'Burkina Faso' },
    { label: 'BI', name: 'Burundi' },
    { label: 'CV', name: 'Cabo Verde' },
    { label: 'CL', name: 'Chile' },
    { label: 'CN', name: 'China' },
    { label: 'CK', name: 'Cookinseln' },
    { label: 'CR', name: 'Costa Rica' },
    { label: 'CU', name: 'Cuba' },
    { label: 'DM', name: 'Dominica' },
    { label: 'DO', name: 'Dominikanische Republik' },
    { label: 'DJ', name: 'Dschibuti' },
    { label: 'DK', name: 'Dänemark' },
    { label: 'EC', name: 'Ecuador' },
    { label: 'SV', name: 'El Salvador' },
    { label: 'CI', name: 'Elfenbeinküste' },
    { label: 'ER', name: 'Eritrea' },
    { label: 'EE', name: 'Estland' },
    { label: 'FK', name: 'Falklandinseln' },
    { label: 'FJ', name: 'Fidschi' },
    { label: 'FI', name: 'Finnland' },
    { label: 'FR', name: 'Frankreich' },
    { label: 'GF', name: 'Französisch-Guayana' },
    { label: 'PF', name: 'Französisch-Polynesien' },
    { label: 'TF', name: 'Französische Südpolarterritorien' },
    { label: 'FO', name: 'Färöer' },
    { label: 'GA', name: 'Gabun' },
    { label: 'GM', name: 'Gambia' },
    { label: 'GE', name: 'Georgien' },
    { label: 'GH', name: 'Ghana' },
    { label: 'GI', name: 'Gibraltar' },
    { label: 'GD', name: 'Grenada' },
    { label: 'GR', name: 'Griechenland' },
    { label: 'GB', name: 'Großbritannien' },
    { label: 'GL', name: 'Grönland' },
    { label: 'GP', name: 'Guadeloupe' },
    { label: 'GU', name: 'Guam' },
    { label: 'GT', name: 'Guatemala' },
    { label: 'GG', name: 'Guernsey' },
    { label: 'GW', name: 'Guinea-Bissau' },
    { label: 'GN', name: 'Guinea' },
    { label: 'GY', name: 'Guyana' },
    { label: 'HT', name: 'Haiti' },
    { label: 'HM', name: 'Heard und McDonaldinseln' },
    { label: 'HN', name: 'Honduras' },
    { label: 'HK', name: 'Hong Kong' },
    { label: 'IN', name: 'Indien' },
    { label: 'ID', name: 'Indonesien' },
    { label: 'IQ', name: 'Irak' },
    { label: 'IR', name: 'Iran' },
    { label: 'IE', name: 'Irland' },
    { label: 'IS', name: 'Island' },
    { label: 'IM', name: 'Isle Of Man' },
    { label: 'IL', name: 'Israel' },
    { label: 'IT', name: 'Italien' },
    { label: 'JM', name: 'Jamaika' },
    { label: 'JP', name: 'Japan' },
    { label: 'YE', name: 'Jemen' },
    { label: 'JE', name: 'Jersey' },
    { label: 'JO', name: 'Jordanien' },
    { label: 'VI', name: 'Jungferninseln, Amerikanische' },
    { label: 'VG', name: 'Jungferninseln, Britische' },
    { label: 'KY', name: 'Kaimaninseln' },
    { label: 'KH', name: 'Kambodscha' },
    { label: 'CM', name: 'Kamerun' },
    { label: 'CA', name: 'Kanada' },
    { label: 'KZ', name: 'Kasachstan' },
    { label: 'KE', name: 'Kenia' },
    { label: 'KG', name: 'Kirgisistan' },
    { label: 'KI', name: 'Kiribati' },
    { label: 'CC', name: 'Kokosinseln' },
    { label: 'CO', name: 'Kolumbien' },
    { label: 'KM', name: 'Komoren' },
    { label: 'CG', name: 'Kongo' },
    { label: 'HR', name: 'Kroatien' },
    { label: 'KW', name: 'Kuwait' },
    { label: 'LA', name: 'Laos' },
    { label: 'LS', name: 'Lesotho' },
    { label: 'LV', name: 'Lettland' },
    { label: 'LB', name: 'Libanon' },
    { label: 'LR', name: 'Liberia' },
    { label: 'LY', name: 'Libyen' },
    { label: 'LI', name: 'Liechtenstein' },
    { label: 'LT', name: 'Litauen' },
    { label: 'MO', name: 'Macao' },
    { label: 'MG', name: 'Madagaskar' },
    { label: 'MW', name: 'Malawi' },
    { label: 'MY', name: 'Malaysia' },
    { label: 'MV', name: 'Maldiven' },
    { label: 'ML', name: 'Mali' },
    { label: 'MT', name: 'Malta' },
    { label: 'MH', name: 'Marshallinseln' },
    { label: 'MQ', name: 'Martinique' },
    { label: 'MR', name: 'Mauretanien' },
    { label: 'MU', name: 'Mauritius' },
    { label: 'YT', name: 'Mayotte' },
    { label: 'MK', name: 'Mazedonien' },
    { label: 'MX', name: 'Mexiko' },
    { label: 'FM', name: 'Mikronesien' },
    { label: 'MD', name: 'Moldawien' },
    { label: 'MC', name: 'Monaco' },
    { label: 'MN', name: 'Mongolei' },
    { label: 'ME', name: 'Montenegro' },
    { label: 'MS', name: 'Montserrat' },
    { label: 'MA', name: 'Marokko' },
    { label: 'MZ', name: 'Mosambik' },
    { label: 'MM', name: 'Myanmar' },
    { label: 'NA', name: 'Namibia' },
    { label: 'NR', name: 'Nauru' },
    { label: 'NP', name: 'Nepal' },
    { label: 'NC', name: 'Neukaledonien' },
    { label: 'NZ', name: 'Neuseeland' },
    { label: 'NI', name: 'Nicaragua' },
    { label: 'NL', name: 'Niederlande' },
    { label: 'AN', name: 'Niederländische Antillen' },
    { label: 'NG', name: 'Nigeria' },
    { label: 'NE', name: 'Niger' },
    { label: 'NU', name: 'Niue' },
    { label: 'KP', name: 'Nordkorea' },
    { label: 'NF', name: 'Norfolkinsel' },
    { label: 'NO', name: 'Norwegen' },
    { label: 'MP', name: 'Nördliche Marianen' },
    { label: 'OM', name: 'Oman' },
    { label: 'PK', name: 'Pakistan' },
    { label: 'PW', name: 'Palau' },
    { label: 'PS', name: 'Palestina' },
    { label: 'PA', name: 'Panama' },
    { label: 'PG', name: 'Papua-Neuguinea' },
    { label: 'PY', name: 'Paraguay' },
    { label: 'PE', name: 'Peru' },
    { label: 'PH', name: 'Philippinen' },
    { label: 'PN', name: 'Pitcairninseln' },
    { label: 'PL', name: 'Polen' },
    { label: 'PT', name: 'Portugal' },
    { label: 'PR', name: 'Puerto Rico' },
    { label: 'QA', name: 'Qatar' },
    { label: 'RW', name: 'Ruanda' },
    { label: 'RO', name: 'Rumänien' },
    { label: 'RU', name: 'Russland' },
    { label: 'RE', name: 'Réunion' },
    { label: 'SB', name: 'Salomonen' },
    { label: 'ZM', name: 'Sambia' },
    { label: 'WS', name: 'Samoa' },
    { label: 'SM', name: 'San Marino' },
    { label: 'SA', name: 'Saudi-Arabien' },
    { label: 'SE', name: 'Schweden' },
    { label: 'SN', name: 'Senegal' },
    { label: 'RS', name: 'Serbien' },
    { label: 'SC', name: 'Seychellen' },
    { label: 'SL', name: 'Sierra Leone' },
    { label: 'ZW', name: 'Simbabwe' },
    { label: 'SG', name: 'Singapur' },
    { label: 'SK', name: 'Slowakische Republik' },
    { label: 'SI', name: 'Slowenien' },
    { label: 'SO', name: 'Somalia' },
    { label: 'ES', name: 'Spanien' },
    { label: 'LK', name: 'Sri Lanka' },
    { label: 'BL', name: 'St. Barthélemy' },
    { label: 'SH', name: 'St. Helena' },
    { label: 'KN', name: 'St. Kitts/Nevis' },
    { label: 'LC', name: 'St. Lucia' },
    { label: 'MF', name: 'St. Martin' },
    { label: 'PM', name: 'St. Pierre/Miquelon' },
    { label: 'VC', name: 'St. Vincent/Die Grenadinen' },
    { label: 'SD', name: 'Sudan' },
    { label: 'SR', name: 'Surinam' },
    { label: 'SJ', name: 'Svalbard/Jan Mayen' },
    { label: 'SZ', name: 'Swasiland' },
    { label: 'SY', name: 'Syrien' },
    { label: 'ST', name: 'São Tomé/Príncipe' },
    { label: 'ZA', name: 'Südafrika' },
    { label: 'GS', name: 'Südgeorgien/Südlichen Sandwichinseln' },
    { label: 'KR', name: 'Südkorea' },
    { label: 'TJ', name: 'Tadschikistan' },
    { label: 'TW', name: 'Taiwan' },
    { label: 'TZ', name: 'Tansania' },
    { label: 'TH', name: 'Thailand' },
    { label: 'TL', name: 'Timor-Leste' },
    { label: 'TG', name: 'Togo' },
    { label: 'TK', name: 'Tokelau' },
    { label: 'TO', name: 'Tonga' },
    { label: 'TT', name: 'Trinidad und Tobago' },
    { label: 'TD', name: 'Tschad' },
    { label: 'CZ', name: 'Tschechoslowakei' },
    { label: 'TN', name: 'Tunesien' },
    { label: 'TM', name: 'Turkmenistan' },
    { label: 'TC', name: 'Turks- und Caicosinseln' },
    { label: 'TV', name: 'Tuvalu' },
    { label: 'TR', name: 'Türkei' },
    { label: 'UG', name: 'Uganda' },
    { label: 'UA', name: 'Ukraine' },
    { label: 'HU', name: 'Ungarn' },
    { label: 'UM', name: 'United States Minor Islands' },
    { label: 'UY', name: 'Uruguay' },
    { label: 'US', name: 'USA' },
    { label: 'UZ', name: 'Usbekistan' },
    { label: 'VU', name: 'Vanuatu' },
    { label: 'VA', name: 'Vatikanstadt' },
    { label: 'VE', name: 'Venezuela' },
    { label: 'AE', name: 'Vereinigte Arabische Emirate' },
    { label: 'VN', name: 'Vietnam' },
    { label: 'WF', name: 'Wallis/Futuna' },
    { label: 'CX', name: 'Weihnachtsinsel' },
    { label: 'BY', name: 'Weißrussland' },
    { label: 'EH', name: 'Westsahara' },
    { label: 'CF', name: 'Zentralafrikanische Republik' },
    { label: 'CY', name: 'Zypern' }
];

export const TABLE_LABELS = {
    name: 'Name',
    content: 'Text',
    'hotelType.stars': 'Sterne',
    'place.destination.name': 'Destination',
    'destination.name': 'Destination',
    'place.area.name': 'Region',
    'area.name': 'Region',
    'place.name': 'Standort',
    buspaketRating: 'Internes Rating',
    url: 'URL',
    agencies: 'Agenturen',
    dataCheckedAt: 'OK',
    type: 'Art',
    category: 'Kategorie',
    routeDescription: 'Routenbeschreibung',
    serviceDescription: 'Leistungsbeschreibung',
    country: 'Land',
    mainUser: 'Mitarbeiter',
    mainUsers: 'Zugeteilt',
    'order.user': 'User',
    'day.order.user': 'User',
    user: 'Bearbeiter',
    mainLanguage: 'Firmensprache',
    importance: 'Wichtigkeit',
    _importId: 'Bussoft Nr',
    'pricePerPerson.formatted': 'Eintritt',
    address: 'Adresse',
    'price.formatted': 'Preis',
    locationCharges: 'Gebühren',
    difficulty: 'Schwierigkeit',
    username: 'Username',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'Email',
    lastLogin: 'Letzter Login',
    checkedCatalogText: 'Katalog',
    trailerMaxLength: 'Anhänger',
    busMaxLength: 'Buslänge',
    subtitle: 'Zusatz',
    tags: 'Tags',
    'days.length': 'Reisedauer',
    groupName: 'Gruppe',
    startAt: 'Beginn',
    endAt: 'Ende',
    'mostUrgentTodo.dueDate': 'Deadline',
    currentStatus: 'Status',
    'day.order.currentStatus': 'Reisestatus',
    'order.currentStatus': 'Reisestatus',
    hotelContingent: 'BS',
    ferryContingent: 'BS',
    'order.hotelContingent': 'BS',
    'order.ferryContingent': 'BS',
    'day.order.hotelContingent': 'BS',
    'day.order.ferryContingent': 'BS',
    provider: 'Leistungsträger',
    'order.id': 'Reisenr.',
    'day.order.id': 'Reisenr.',
    'client.shortName': 'Kunde',
    'order.client.shortName': 'Kunde',
    'day.order.client.shortName': 'Kunde',
    client: 'Kunde',
    'client.companyName': 'Kunde',
    'order.client.companyName': 'Kunde',
    'day.order.client.companyName': 'Kunde',
    destinations: 'Destinationen',
    'trip.destinations': 'Destinationen',
    'order.trip.destinations': 'Destinationen',
    'day.order.trip.destinations': 'Destinationen',
    ports: 'Hafen',
    airports: 'Flughafen',
    'otherServiceType.name': 'Zusatzleistung',
    'otherServiceType.routeDescription': 'Routenbeschreibung',
    'info.personsReserved': 'Reise BS',
    subject: 'Betreff',
    roomType: 'Zimmertyp',
    hotelType: 'Hotelkategorie',
    board: 'Verpflegung',
    days: '',
    cabinType: 'Kabinentyp',
    months: 'Zeitraum',
    title: 'Titel',
    standard: 'Automatisch hinzufügen',
    receivesCatalog: 'Katalog',
    checkStatus: 'Status',
    providers: 'Anbieter'
};

export const colors = [
    '#60A4AB', // blue-light
    'rgba(96,164,171,0.2)', // blue
    '#2a464f', // blue-dark

    '#FF3860', // red
    '#FF6A6A', // red-light

    '#F7EEED', // pink
    '#E68C87', // pink dark

    '#FFB606', // yellow

    '#62CB31', // green
    '#41b883', // ocean green

    '#995AB1', // purple

    '#9F4C0F', // brown

    '#60A4AB', // turqoise,

    '#E6AB87', // orange
];

export const UNAUTHORIZED = 401;
export const ACCESS_DENIED = 403;

export const todoStatuses = [
    { label: 'offen', value: 'open', icon: 'radio_button_unchecked' },
    { label: 'wartend', value: 'waiting', icon: 'pause' },
    { label: 'erledigt', value: 'done', icon: 'check' }
];

export const editorToolbar = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ['small', false, 'large', 'huge'] }],
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ font: ['open-sans', 'open-sans-light', 'open-sans-semi-bold'] }],
    [
        { align: '' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' }
    ],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    ['link', 'image', 'video'],
    ['clean'] // remove formatting button
];

export const editorOptions = {

};

export const excelColumns = function (type) {
    return {
        transfer: [
            {
                column: 'A',
                count: true,
                label: '#'
            },
            {
                column: 'B',
                property: {
                    name: 'invoiceNumber'
                },
                label: 'RechnungsNr'
            },
            {
                column: 'C',
                method: {
                    name: 'getOrderClientString'
                },
                label: 'Reisen',
                format: 'text'
            },
            {
                column: 'D',
                method: {
                    name: 'getIssuerName'
                },
                label: 'Rechnungssteller'
            },
            {
                column: 'E',
                property: {
                    name: 'invoiceDate'
                },
                label: 'Rechnungsdatum'
            },
            {
                column: 'F',
                property: {
                    name: 'dueDate'
                },
                label: 'Zahlungsziel'
            },
            {
                column: 'G',
                property: {
                    name: 'invoiceAmount'
                },
                label: 'Rechnungsbetrag',
                sum: true,
                format: '#,##0_-"€"'
            },
            {
                column: 'H',
                property: {
                    name: 'bankAccount.iban'
                },
                label: 'IBAN'
            },
            {
                column: 'I',
                property: {
                    name: 'bankAccount.bic'
                },
                label: 'BIC'
            },
            {
                column: 'J',
                property: {
                    name: 'notes'
                },
                label: 'Notizen',
                format: 'text'
            }
        ],

        payment: [
            {
                column: 'A',
                count: true,
                label: '#'
            },
            {
                column: 'B',
                property: {
                    name: 'invoiceNumber'
                },
                label: 'RechnungsNr'
            },
            {
                column: 'C',
                method: {
                    name: 'getOrderClientString'
                },
                label: 'Reisen',
                format: 'text'
            },
            {
                column: 'D',
                method: {
                    name: 'getIssuerName'
                },
                label: 'Rechnungssteller'
            },
            {
                column: 'E',
                property: {
                    name: 'invoiceDate'
                },
                label: 'Rechnungsdatum'
            },
            {
                column: 'F',
                property: {
                    name: 'invoiceAmount'
                },
                label: 'Rechnungsbetrag',
                sum: true,
                format: '#,##0_-"€"'
            },
            {
                column: 'G',
                property: {
                    name: 'bankAccount.iban'
                },
                label: 'IBAN'
            },
            {
                column: 'H',
                property: {
                    name: 'bankAccount.bic'
                },
                label: 'BIC'
            },
            {
                column: 'I',
                property: {
                    name: 'bankAccount.bank'
                },
                label: 'Bank'
            },
            {
                column: 'J',
                property: {
                    name: 'paymentDate'
                },
                label: 'Bezahlt am'
            }
        ],
    }[type];
};
