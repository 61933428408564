export const settings = {
    travelizeLanguage: 'fr',
    modules: {
        websiteExport: false,
        webflow: false,
        ai: false,
    },
    general: {
      resetFilter : true,
    },
    pagination: {
        order_concepts: {
            perPage: 80
        }
    },
    crud: {
        menus: {
            tipTap: {
                maxLines: 6,
                maxCharacters: 60,
            },
            defaultOtherServiceTypeId: 41
        },
        clients: {
            list: {
                general: {
                    //[
                    //                     { key: 'id', label: 'Nr', class: 'is-small-cell' },
                    //                     { key: 'companyName', label: 'Kundenname', class: 'is-one-fifth' },
                    //                     { key: 'zip', label: 'PLZ', sortable: 'addresses.zip', filter: 'addresses.zip'},
                    //                     { key: 'addresses', label: 'Ort', filter: 'addresses', sortable: 'addresses.city'},
                    //                     { key: 'addressCountry', label: 'Land', filter: 'addresses.country', sortable: 'addresses.country'},
                    //                     { key: 'importance', sortable: 'importance', filter: 'importance'},
                    //                     'url',
                    //                     { key: 'traitTags', sortable: false, label: 'Tags', class: 'is-one-tenth', filter: 'traitTags'},
                    //                     { key: 'mainUsers', filter: 'mainUsers.id'},
                    //                     { key: 'receivesCatalog', filter:  'receivesCatalog'},
                    //                     { key: 'dataCheckedAt',},
                    //                 ]
                    columns: [
                        { key: 'id', label: 'Nr', class: 'is-small-cell' },
                        { key: 'companyName', label: 'Kundenname', class: 'is-one-fifth' },
                        { key: 'zip', label: 'PLZ', sortable: 'addresses.zip', filter: 'addresses.zip'},
                        { key: 'addresses', label: 'Ort', filter: 'addresses', sortable: 'addresses.city'},
                        { key: 'addressCountry', label: 'Land', filter: 'addresses.country', sortable: 'addresses.country'},
                        { key: 'importance', sortable: 'orders'},
                        { key: 'url', sortable: false, class: 'is-200px-cell'},
                        { key: 'traitTags', sortable: false, label: 'Tags', class: 'is-200px-cell', filter: 'traitTags'},
                        { key: 'receivesCatalog', filter:  'receivesCatalog', sortable: false, class: 'is-80px-cell has-text-centered'},
                        { key: 'hasLinkedIn', cellKey: 'check', label: 'LinkedIn', filter:  'hasLinkedIn', sortable: false, class: 'is-80px-cell has-text-centered'},
                        { key: 'clientAcquisition', label: 'Akquise', cellKey:'valueFromOption', props: {optionKey: 'clientAcquisition', trackBy: 'key', label: 'value'}, sortable: false, class: 'is-140px-cell is-hidden-on-small'},
                        { key: 'endCustomerType', cellKey:'valueFromOption', label: 'Endkunden', props: {optionKey: 'endCustomerType', trackBy: 'key', label: 'value'}, sortable: false, class: 'is-140px-cell is-hidden-on-small'},
                        { key: 'mainCurrency', label: 'CUR', sortable: false, class: 'is-50px-cell has-text-centered is-hidden-on-small'},
                        { key: 'mainUsers', label: 'User', sortable: false, class: 'is-60px-cell is-right'},
                        { key: 'salesUsers', cellKey: 'mainUsers', label: 'Sales', sortable: false, class: 'is-60px-cell is-right is-hidden-on-small'},
                    ],
                    filterKeys: ['category', 'paymentType', 'destinations'],
                    extraFilters: [
                        {
                            key: 'mainUsers.id',
                            params: {
                                'username[not]': ['Julien', 'Accounting']
                            }
                        },
                        {
                            key: 'salesUsers.id',
                            params: {
                                'username[not]': ['Julien', 'Accounting']
                            }
                        },
                        {
                            key: 'importance',
                        }
                    ]
                },
                sales: {
                    columns: [
                        { key: 'id', label: 'Nr', class: 'is-small-cell' },
                        { key: 'companyName', label: 'Kundenname', class: 'is-one-fifth' },
                        { key: 'importance[-1]', cellKey: 'importance', label: 'Wichtigkeit [t-1]', sortable: false},
                        { key: 'importance[0]', cellKey: 'importance', label: 'Wichtigkeit [t]', sortable: false},
                        { key: 'importance[1]', cellKey: 'importance', label: 'Wichtigkeit [t+1]', sortable: false},
                        { key: 'revenues[-1]', label: 'Umsatz [t-1]', sortable: false},
                        { key: 'revenues[0]', label: 'Umsatz [t]', sortable: false},
                        { key: 'revenues[1]', label: 'Umsatz [t+1]', sortable: false},
                        { key: 'bookingRatio[-1]', label: 'Conversion Rate [t-1]', sortable: false},
                        { key: 'bookingRatio[0]', label: 'Conversion Rate [t]', sortable: false},
                        { key: 'bookingRatio[1]', label: 'Conversion Rate [t+1]', sortable: false},
                        { key: 'lastClientVisit', label: 'Letzter Kundenbesuch'},
                    ],
                    filterKeys: ['addresses.zip', 'addresses.city', 'addresses.country', 'traitTags', 'receivesCatalog', 'category', 'hasLinkedIn', 'paymentType', 'destinations'],
                    extraFilters: [
                        {
                            key: 'salesUsers.id',
                            params: {
                                'username[not]': ['Julien', 'Accounting']
                            }
                        },
                        {
                            key: 'importance',
                        }
                    ]
                }
            }

        }
    },
    order: {
        tripDashboard: {
            showRouteDetailStatuses: ['new','offer_sent', 'provisional_offer_sent'],
            rightPanel: {
                additionalFields: [
                    {
                        property: 'parisId',
                        title: 'Paris Nr',
                        icon: 'computer',
                        isInEditMode: false
                    },
                ]
            }
        },
        contacts:  {
            hideBus: true,
        },
        modal: {
            assignedContactAddress: true,
        },
        placeholderForms: {
            other: {
                requiresDriverIsNotParticipating: ['boat_trip', 'train_ride']
            },
            ferry: {
                requiresDriverIsNotParticipating: ['day']
            },
            train: {
                requiresDriverIsNotParticipating: ['day']
            }
        },
        navigation: [
            {url: '', label: 'Reisemaske', showOnSmall : true},
            {url: 'calculations', label:  'Kalkulation', showOnSmall : true},
            {url: 'offer', label: 'Angebot'},
/*
            {url: 'catalog', label: 'Katalogtext'},
*/
            {url: 'menus', label: 'Menüs'},
            {url: 'roomlist', label: 'Zimmerliste'},
            {url: 'trip-details', label: 'Reiseunterlagen'},
/*
            {url: 'voucher', label: 'Voucher'},
*/
            {url: 'client-invoice', label: 'Kundenrechnungen'},
            {url: 'invoices', label: 'Zahlungen an LT'},
            {url: 'documents', label: 'Dokumente'},
            {url: 'emails', label: 'Emails'},
        ],
    },
    offer: {
        tabs: {
            offer: true,
            freePlaces : false,
            cancelation: true,
            payments: true,
            conditions: true,
            other: false,
        },
        freePlaces: {
            showRoomType: false,
            conditionMap: [
                {
                    text: 'Une personne gratuite par 20 payantes.',
                    freePlaces: [
                        {
                            freePlaces: 1,
                            numberOfPersons: 21,
                            type: 'single',
                            key: 1
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 42,
                            type: 'single',
                            key: 2
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 63,
                            type: 'single',
                            key: 3
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 84,
                            type: 'single',
                            key: 3
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 105,
                            type: 'single',
                            key: 3
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 126,
                            type: 'single',
                            key: 3
                        },
                    ]
                },
                {
                    text: 'Deux personne gratuite par 40 payantes.',
                    freePlaces: [
                        {
                            freePlaces: 1,
                            numberOfPersons: 42,
                            type: 'single',
                            key: 1
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 42,
                            type: 'single',
                            key: 2
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 84,
                            type: 'single',
                            key: 1
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 84,
                            type: 'single',
                            key: 2
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 126,
                            type: 'single',
                            key: 1
                        },
                        {
                            freePlaces: 1,
                            numberOfPersons: 126,
                            type: 'single',
                            key: 2
                        },
                    ]
                }
            ]
        },
        bullets: {
            show: false
        },
        generationTypes: {
            offerte: {
                key: 'offerte',
                value: 'Offerte',
                default: true
            },
            route: {
                key: 'route',
                value: 'Route',
                default: true
            },
            calculation: {
                key: 'calculation',
                value: 'Kalkulation',
                default: true
            },
            detailedRoute: {
                key: 'detailedRoute',
                value: 'Fahrplan',
                default: false
            },
            allotment: {
                key: 'allotment',
                value: 'Allotment',
                default: false
            },
        },
    },
    userDashboard: {
        resetFilterToToday: false,
        todoSorting: {
            '_custom_order[dueDate]': 'asc',
            '_custom_order[todoType]': ['phone', 'visit'],
            '_custom_order[type]': ['external', 'internal'],
            '_custom_order[order.startAt]': 'asc',
        },
    },
    emails: {
        organisationHosts : ['@rstours.ch'],
        isFormal: {
            client: false,
            provider: true,
        },
        selectedIsFormal: {
            client: true,
            provider: true,
        },
        categories: {
            client : {
                icon: "folder",
                value: "client",
                label: "client",
                standardClient : true,
                standardProvider: false,
            },
            provider: {
                icon: "hotel",
                value: "provider",
                label: "provider",
                standardClient : false,
                standardProvider: true,
            }
        }
    },
    calculation: {
        singleRoomLimit: 'partial',
        startView : 'day',
        extraBlocks: [
            {
                offerTextBlock: 'hotelSuplements',
                label: 'Mehrbettzimmer und sonstige Zimmer',
            },
            {
                offerTextBlock: 'ferrySuplements',
                label: 'Außenkabinen',
            },
            {
                offerTextBlock: 'trainSuplements',
                label: 'Zugfahrten 1. Klasse',
            },
            {
                offerTextBlock: 'ferryOther',
                label: 'zusätzliche Leistungen an Bord der Fähre',
            },
            {
                offerTextBlock: 'foodOnBoard',
                label: 'zusätzliche Leistungen an Bord der Fähre',
            },
            {
                offerTextBlock: 'tourGuides',
                label: 'zusätzliche Reiseleiter-Leistungen',
            },
            {
                offerTextBlock: 'groupServices',
                label: 'zusätzliche Gruppen-Leistungen',
            },
            {
                offerTextBlock: 'services',
                label: 'zusätzliche Leistungen pro Person',
            },
            {
                offerTextBlock: 'mainPriceDates',
                label: 'Paketpreise',
            },
            {
                offerTextBlock: 'reduction',
                label: 'Reduktionen pro Person',
            },
            {
                offerTextBlock: 'reductionPerGroup',
                label: 'Reduktionen pro Gruppe',
                type: 'per_group'
            },
            {
                offerTextBlock: 'tax',
                label: 'Kurtaxe',
            },

        ],
    },
    providerInvoice: {
        hasInvoiceDetails: true,
        hideArrow: true,
        startSection: 'InvoiceList',
        disableCompleted: 'book_keeper',
        output: 'word',
        setStatusAtDownload: {
            key: 'payment_in_process',
            value: 'in Arbeit',
        },
        allowedStatusSequence: {
            'sent_for_payment': ['paid', 'payment_in_process', 'cancelled'],
            'payment_in_process': ['paid', 'sent_for_payment'],
            'paid': ['completed', 'sent_for_payment'],
            'completed': ['paid'],
            'cancelled': ['sent_for_payment']
        },
        form: {
            issuer: true,
            bankAccount: true,
            type: 'final_payment',
            invoiceCurrency: true,
            invoiceAmount: true,
            invoiceNumber: true,
            invoiceDate: true,
            dueDate: true,
            status: null,
            notes: true,
            paymentReceiptRequired: true,
            user: true,
            document: true,
            organisationBankAccount: false,
            checkNumber: false,
            paymentType: true,
        },
        formDefaultValues: {
        },
        formValidations: [
            'invoiceNumber',
            'invoiceAmount',
            'invoiceDate',
            'bankAccount',
            'document',
        ],
        paymentForm: {
            paymentDate: true,
            bankAccount: false,
            accountPlanItem: false,
            checkNumber: false,
        },
        createInvoice: {
            filters: {
                requestStatuses: ['pre_booked', 'option_received', 'option', 'rest_request', 'bookings_sent', 'rest_option', 'approved', 'paid']
            }
        },
        invoiceList: {
            statusesSelected: ['sent_for_payment', 'payment_in_process', 'paid', 'completed'],
        },
        tabs: [
            { key: 'InvoiceList', label: 'Rechnungen von LT'},
            { key: 'OpenAmountList', label: 'Offene Beträge LT'},
        ],
        columns: [
            { key: 'checkable', label:  '', class: 'is-icon-cell  is-relative', style:'padding: 0px; justify-content: center;'},
            { key: 'favicon', formElement: 'Favicon', label: 'User', class: 'is-user-cell has-text-centered has-border is-hidden-on-small'},
            { key: 'invoiceNumber', label: 'R-Nr', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'tripLabel', label:  'Reisen', class: ' has-border is-pure-text', formElement: 'tripLabel'},
            { key: 'info.issuer', label:  'Rechnungssteller', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'bankIcons', label:  'Bankverbindung', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'bankIcons'},
            { key: 'invoiceAmount.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText' },
            { key: 'invoiceAmount.numberFormatted', label:  'R-Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'invoiceDate', label:  'R-Datum', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {isDate: true} },
            { key: 'dueDateTransformed', label: 'Zahlungsziel', class: 'is-110px-cell has-border has-text-centered', formElement: 'InputDateTable', props: {onlyDate: false, showDeadlineUrgency: true, readOnly: true} },
            { key: 'paymentDate', label: 'Bezahlt am', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {isDate: true} },
            { key: 'status', label: 'Status', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'document', label: 'Zahlungsbeleg', class: 'is-135px-cell has-text-centered has-border', formElement: ''},
            { key: 'paymentType', label:  'Abbuchung', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {keyValueOptionPath: 'paymentType'} },
            { key: 'notes', label:  'Notizen', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],

        subColumns: [
            { key: 'empty-checkable', label:  '', class: 'is-icon-cell  is-relative', formElement: '',style:'padding: 0px; justify-content: center;'},
            { key: 'empty-favicon', label: '', class: 'is-user-cell has-text-centered has-border is-hidden-on-small', formElement: ''},
            { key: 'empty-invoiceNumber', label: 'Rechnungspositionen', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'clientLabel', label:  'Reise', class: 'has-border is-pure-text', formElement: 'tripLabel'},
            { key: 'serviceDescription', label: 'Leistung', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'startEndAt', label:  'Datum', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'PureText', props: {isDate: true}},
            { key: 'invoiceVars.amountDue.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText'},
            { key: 'invoiceVars.amountDue.numberFormatted', label:  'Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'empty-invoiceDate', label:  '', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText'},
            { key: 'empty-dueDateTransformed', label: '', class: 'is-110px-cell has-border has-text-centered', formElement: '' },
            { key: 'empty-paymentDate', label: '', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
            { key: 'empty-status', label: '', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'empty-document', label: '', class: 'is-135px-cell has-text-centered has-border', formElement: ''},
            { key: 'empty-paymentType', label:  '', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: '' },
            { key: 'empty-notes', label:  '', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],
        columnsShorter: [
            { key: 'invoiceNumber', label: 'R-Nr', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'info.issuer', label:  'Rechnungssteller', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'bankIcons', label:  'Bankverbindung', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'bankIcons'},
            { key: 'invoiceAmount.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText' },
            { key: 'invoiceAmount.numberFormatted', label:  'R-Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'invoiceDate', label:  'R-Datum', class: 'is-date-cell is-pure-text has-text-centered has-border', formElement: 'PureText', props: {isDate: true} },
            { key: 'dueDateTransformed', label: 'Zahlungsziel', class: 'is-110px-cell has-border has-text-centered is-hidden-on-small', formElement: 'InputDateTable', props: {onlyDate: false, showDeadlineUrgency: true, readOnly: true} },
            { key: 'paymentDate', label: 'Bezahlt am', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {isDate: true} },
            { key: 'status', label: 'Status', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'document', label: 'Zahlungsbeleg', class: 'is-135px-cell has-text-centered has-border', formElement: ''},
            { key: 'paymentType', label:  'Abbuchung', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {keyValueOptionPath: 'paymentType'} },
            { key: 'notes', label:  'Notizen', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],
        subColumnsShorter: [
            { key: 'empty-invoiceNumber', label: 'Rechnungspositionen', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'serviceDescription', label: 'Leistung', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'startEndAt', label:  'Datum', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'PureText', props: {isDate: true}},
            { key: 'invoiceVars.amountDue.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText'},
            { key: 'invoiceVars.amountDue.numberFormatted', label:  'Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'empty-invoiceDate', label:  '', class: 'is-date-cell is-pure-text has-text-centered has-border', formElement: 'PureText'},
            { key: 'empty-dueDateTransformed', label: '', class: 'is-110px-cell has-border has-text-centered is-hidden-on-small', formElement: '' },
            { key: 'empty-paymentDate', label: '', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
            { key: 'empty-status', label: '', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'empty-document', label: '', class: 'is-135px-cell has-text-centered has-border', formElement: ''},
            { key: 'empty-paymentType', label:  '', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: '' },
            { key: 'empty-notes', label:  '', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],
        subColumnsShorter2: [
            { key: 'serviceDescription', label: 'Leistung', formElement: 'PureText',  class:"is-250px-cell", style:"padding:0 6px;", props: {baseUrl: '/orders/'}, evalProps: {urlId: 'orderId'} },
            { key: 'invoiceVars.amountDue.currency', label: '', class:"is-70px-cell", style:"padding:0 6px;", formElement: 'PureText' },
            { key: 'invoiceVars.amountDue.numberFormatted', label:  'Gesamtkosten', class:"is-150px-cell", style:"padding:0 6px;", formElement: 'PureText' },
            { key: 'invoiceVars.startAt', label:  'Beginn', class:"is-150px-cell", style:"padding:0 6px;", formElement: 'PureText', props: {isDate: true} },
            { key: 'invoiceVars.endAt', label: 'Ende', class:"is-150px-cell", style:"padding:0 6px;", formElement: 'PureText', props: {isDate: true} },
            { key: 'empty-2', label: '', class:"is-60px-cell", style:"padding:0 6px;", formElement: 'PureText' },
            { key: 'empty-3', label:  '', class:"", formElement: 'PureText' },
            { key: 'empty-4', label:  '', formElement: 'PureText' },
            { key: 'empty-5', label:  '', class: '',style:'max-width: 83px;', formElement: '' },
        ]
    },
    emailTemplate: {
        startSection: 'Templates'
    },
    clientInvoice: {
        disableCompleted: 'book_keeper',
        docType: 'word',
        fields: {
            tax: [
                {
                    value: 0,
                    isSpecial: false,
                    label: '0 %',
                    accountingNumber: 3010,
                },
                {
                    value: 3.8,
                    isSpecial: false,
                    label: '3.8 %',
                    accountingNumber: null,
                },
                {
                    value: 8.1,
                    isSpecial: false,
                    label: '8.1 %',
                    accountingNumber: 3011,
                },
                {
                    value: -1,
                    isSpecial: true,
                    label: 'Exempt de TVA – TVA Groupe Buchard',
                    accountingNumber: null,
                },
            ],
            invoiceDate: true,
            calculationBasis: false,
            dueDate: true,
            contact: false,
            introText: true,
            endText: true,
            signature: false,
            showTax: true,
        },
        columns: [
            { key: 'favicon', label: 'User', class: 'is-user-cell', sortable: 'order.user.username' },
            { key: 'invoiceNumber', filter: 'invoiceNumber', label: 'Re-NR' },
            { key: 'linkedOrderNumber', filter: 'order', label: 'Reise' },
            { key: 'order.client.shortName', label: 'Kunde', class: 'is-180px-cell' },
            { key: 'order.trip.name', label: 'Reiseziel', class: 'is-180px-cell is-hidden-on-small' },
            { key: 'order.startAt', label: 'Reisebeginn', },
            { key: 'order.endAt', label: 'Reiseende', showSum: 'Summe' },
            { key: 'invoiceDate', filter: 'invoiceDate', label: 'R-Datum', class: 'is-hidden-on-small'},
            { key: 'dueDate', filter: 'dueDate', label: 'Zahlungsziel', class: 'is-120px-cell is-editable is-hidden-on-small', showSum: 'Summe'},
            { key: 'currency', label: 'CUR', class: 'is-small-cell', filter: 'amount.currency' },
            { key: 'order.totalExpectedRevenues.numberFormatted', label: 'Betrag aktuell', class: 'has-text-right  is-120px-cell is-hidden-on-small', showSum: 'price' },
            { key: 'amount.numberFormatted', label: 'Berechnet', class: 'has-text-right', showSum: 'price' },
            { key: 'paidAmount.numberFormatted', label: 'Bezahlt', class: 'has-text-right', showSum: 'price' },
            { key: 'openAmount.numberFormatted', label: 'Saldo', class: 'has-text-right', showSum: 'price' },
            { key: 'status', filter: 'status', label: 'Status', hideContent: true, class: 'is-medium-cell is-editable'},

        ],
        orderBy: {
            '_order[invoiceDate]': 'DESC',
        },
        mainCurrency: 'CHF',
        exportCurrentInvoice: [],
    },
    voucher: {
        translations: true,
        translationsInOne: true,
        availableTranslations: ['de_DE', 'fr', 'en'],
        translationDelimiter: "", //not needed since editor makes automatic line break,
        hasInternalVoucher: true,
        tipTap: {
            maxCharacters: 60,
        }
    },
    roomList: {
        create: {
            fields: {
                notes: true
            }
        },
        types: [
            {key: 'templateA', label: 'Zimmerliste', default: true},
            {key: 'templateB', label: 'Passagierliste', default: false},
            {key: 'templateC', label: 'Passagierliste mit Motorrad', default: false},
        ],
        templateA: {
            fields: {
                extraType: true,
                twin: true,
                gender: false,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: false,
                dateOfBirth: false,
                placeOfBirth: false,
                documentType: false,
                documentNumber: false,
                assistance: false,
                outsideCabin: false,
                allergies: true,
                notes: true
            }
        },
        templateB: {
            fields: {
                extraType: true,
                twin: false,
                gender: true,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: true,
                dateOfBirth: true,
                placeOfBirth: true,
                documentType: true,
                documentNumber: true,
                assistance: true,
                outsideCabin: true,
                allergies: true,
                notes: true
            }
        },
        templateC: {
            fields: {
                extraType: true,
                twin: false,
                gender: true,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: true,
                dateOfBirth: true,
                placeOfBirth: true,
                documentType: true,
                documentNumber: true,
                assistance: true,
                outsideCabin: true,
                notes: true,
                motorBikeModel: true,
                licencePlate: true
            }
        },
        sources: [
                {label: 'RsTours', key: 'buspaket', default: true},
                //{label: 'Toeffreisen', key: 'toeffreisen', default: false},
                //{label: 'Reisen & Erleben', key: 'reisenUndErleben', default: false}
            ]
    },
    liquidityPlan: {
        isUsed: false,
        customExports: [
            {
                fileType: '.xlsx',
                active: true,
                type: 'monthly',
                label: 'V & V erstellen',
                url: '/api/finances/custom-statement/generate-excel',
                downloadName: 'Vermietung und Verpachtung Kirchheim'
            },
            {
                fileType: '.xlsx',
                active: true,
                type: 'yearly',
                label: 'UmSt Vergütung',
                url: '/api/finances/custom-tva-statement/generate-excel',
                downloadName: 'Umsatzsteuervergütung'
            },
            {
                fileType: '.xlsx',
                active: true,
                type: 'yearly',
                label: 'UmSt Vergütung CSV',
                url: '/api/finances/custom-tva-statement/generate-csv',
                downloadName: 'Umsatzsteuervergütung'
            }
        ],
    },

};

